import Web3 from "web3";
import contract from  "../contract/contract.json";
import contractTestnet from  "../contract/pet_number_v2.2.json";
import rewardContractMainNet from  "../contract/easter_contract.json";

import { useCallback, useEffect, useState } from "react";
import React from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
const initialInfo={
    connected: false,
    status: null,
    account: null,
    contract: null
};

const initialPetState = {
    loading: false,
    count:null,
    list:[],
};

const initialSupplyState = {
    loading: false,
    supply:0,
};

const initialVisorPetState = {
    loading: false,
    count:null,
    list:[],
};

const defaultContract = contract;

const opcodes = {
    '00':'TRAP',
    '01':'FCMP',
    '02':'FUN',
    '03':'FEQL',
    '04':'FADD',
    '05':'FIX',
};

const Pet = () => {
    const [info, setInfo] = useState(initialInfo);
    const [pets, setPets] = useState(initialPetState);
    const [supplyInfo, setSupply] = useState(initialSupplyState);
    const [mintingPrice, setMintingPrice] = useState(0);
    const [output, setOutput] = useState('');
    const [minting, setMinting] = useState(false);
    const [changing, setChanging] = useState({
        isChanging:false,
        disabled:true
    });

    const [changingToken, setChangingToken] = useState({
        isChanging:false,
        disabled:true
    });

    const [visorPets, setVisorPets] = useState(initialVisorPetState);
    const [explorer, setExplorer] = useState('https://polygonscan.com/address/');
    const [contractAddr, setContractAddr] = useState('');
    const [contractVisor, setContractVisor] = useState(null);

    const init =  useCallback(async () => {
        const web3Provider = new Web3.providers.HttpProvider('https://polygon-rpc.com/');
        let web3v = new Web3(web3Provider);
        const tmpContract = new web3v.eth.Contract(defaultContract.abi, defaultContract.address);
        setContractVisor(tmpContract);

        if (window.ethereum?.isMetaMask){
            const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            const networkId = await window.ethereum.request({
                method: "net_version",
            });
            console.log(networkId);
            let contractNetwork;
            if(networkId === "137"){
                contractNetwork = contract;
                setExplorer('https://polygonscan.com/address/');
                setContractAddr(contractNetwork.address);
                // setContractVisor(contractNetwork);
            }
            
            else if (networkId==="80001"){
                contractNetwork = contractTestnet;
                setExplorer('https://mumbai.polygonscan.com/address/');
                setContractAddr(contractNetwork.address);
                // setContractVisor(contractNetwork);
            }else{
                setInfo({...initialInfo, status:"You need to be connected to the Polygon network"});
                return;
            }
            let web3 = new Web3(window.ethereum);
                let mycontract = new web3.eth.Contract(contractNetwork.abi, contractNetwork.address);
                
                
               
                const temporalInfoState = {
                    ...initialInfo,
                    connected: true,
                    account: accounts[0],
                    contract: mycontract
                };

                setInfo(temporalInfoState);
                
                getTotalSupply(mycontract);
                getPrice(mycontract);
                getPets(temporalInfoState);
                // web3.eth.subscribe("logs",contractNetwork.address,
                // (error, result)=>{
                //     if(error){
                //         console.error(error);
                //     }else{
                //         console.log(result);
                //     }
                // });
                  
        }else{
            setInfo({...initialInfo, status:"You need metaMask"});
        }
    },[]);

    const initOnChanged = () => {
        if(window.ethereum){
            window.ethereum.on("accountsChanged", () => {
                window.location.reload();
            });
            window.ethereum.on("chainChanged", () => {
                window.location.reload();
            });
            let web3 = new Web3(window.ethereum);
                let mycontract = new web3.eth.Contract(contractTestnet.abi, contractTestnet.address);
             
            mycontract.events.Transfer({}, 
                function(error, event){ console.log(event);})
                .on('data',function(event){
                console.log(event);
                console.log("Before setSupply");
                // setSupply(()=>({
                //         supply: amount,
                //         loading: false,
                //     }));
                //     console.log('Amount');
                //     console.log({amount});
    
            }).on('error',function(error, receipt){ 
                console.log(error);
                console.log(receipt);
            }); 
        }
    };
    const onMinted = async () => {
        // // if(!showCxnStatus()){return}
        // info.contract.events.Transfer().on('data',(event)=>{
        //     console.log(event);
        //     console.log("Before setSupply");
        //     // setSupply(()=>({
        //     //         supply: amount,
        //     //         loading: false,
        //     //     }));
        //     //     console.log('Amount');
        //     //     console.log({amount});

        // }).on('error', console.error);
        
    };

    const showCxnStatus = () =>{
        if (info==null || !info.connected){
            alert(info.status);
            return false;
        }
        return true;
    }
    // const containRewards = () =>{
    //     if (info!=null) {
    //     info.reward_contract.methods
    //         .potentialReward()
    //         .call()
    //         .then((valid) => {
    //             console.log(valid);
                
    //             setRewarding((prevState)=>({
    //                 ...prevState,
                 
    //                 disabled: !valid
    //             }));
    //         return valid;
    //     });
    // }
    //     return false;
        
    // }

    // const getPetCount = async () => {
    //     info.contract.methods
    //     .balanceOf(info.account)
    //     .call()
    //     .then((res) => {
    //         console.log(res);
    //         return res;
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //     });
    // };

    
    // let Transfer =  info.contract.methods.Transfer()
    // Transfer.watch((err, result) => {
    //   if (err) {
    //     console.log('could not get event Transfer()');
    //   } else {
    //     console.log('result');
    //     console.log(result);
    //   }
    // });

    const mintPet = async () =>{
        if (!showCxnStatus()){return}
        setMinting(true);
        setOutput("Minting...");
        info.contract.methods
        .getPrice()
        .call()
        .then((price) => {
            console.log(price);
            info.contract.methods
        .mint()
        .send({
            gasLimit: String(285000),
            to: contract.address,
            from: info.account,
            value: price
        })
        .once("error", (err) => {
            console.log(err);
            setMinting(false);
            setOutput("Sorry, something went wrong please try again later.")
              
            setTimeout(() => {
                setOutput('');
              }, 5000);
          })
          .then((receipt) => {
            console.log(receipt);
            setMinting(false);
         
            setOutput("Congratulations, Pet Number "+receipt.events.Transfer.returnValues.tokenId+" now is yours!");
               
            setTimeout(() => {
                setOutput('');
              }, 5000);
            // setFeedback(
            //   `Owner of the Pet.`);
            // setSupply(()=>({
            //     supply: amount,
            //     loading: false,
            //     }));
            getPrice(info.contract);
            getTotalSupply(info.contract);
            getPets(info);
            
        });
        
        });
        
        

            
   
    }
    let textInput = React.createRef();  // React use ref to get input value
    let tokenIdInput = React.createRef();  // React use ref to get input value

    const changePainter = async (event) =>{
        if (!showCxnStatus()){return}
        setChanging({
            isChanging:true,
            
            disabled: true
        });
        setOutput("Changing Painter...");
        console.log(textInput.current.value)
        const address = validateAddress( textInput.current.value);
        info.contract.methods
        .getPainterUpdateCost(address)
        .call()
        .then((price) => {
            console.log(price);
            info.contract.methods
        .updatePainter(address)
        .send({
            gasLimit: String(485000),
            to: contract.address,
            from: info.account,
            value: price
        })
        .once("error", (err) => {
            console.log(err);
            setChanging({
                isChanging:false,
                
                disabled: false
            });
            setOutput("Sorry, something went wrong please try again later.");
            setTimeout(() => {
                setOutput('');
              }, 5000);
            // setFeedback("Sorry, something went wrong please try again later.");
          })
          .then((receipt) => {
            console.log(receipt);
            setChanging({
                isChanging:false,
                
                disabled: false
            });
            setOutput("You changed the Painter.");
            setTimeout(() => {
                setOutput('');
              }, 5000);
            // setFeedback(
            //   `Owner of the Pet.`);
            getPets(info);
          });
        
        });
        
       

            
   
    }

    // const getReward = async (event) =>{
    //     if (!showCxnStatus()){return}
    //     if (!containRewards()){return}
        
    //     setRewarding({
    //         isRewarding:true,
            
    //         disabled: true
    //     });
    //     setOutput("Getting Reward...");
    //     console.log(textInput.current.value)
    //     const address = validateAddress( textInput.current.value);
    //     info.reward_contract.methods
    //     .potentialReward()
    //     .call()
    //     .then((valid) => {
    //         console.log(valid);
    //         if(valid){
    //         info.reward_contract.methods
    //     .getReward()
    //     .once("error", (err) => {
    //         console.log(err);
    //         setRewarding({
    //             isRewarding:false,
                
    //             disabled: false
    //         });
    //         setOutput("Sorry, something went wrong please try again later.");
    //         setTimeout(() => {
    //             setOutput('');
    //           }, 5000);
    //         // setFeedback("Sorry, something went wrong please try again later.");
    //       })
    //       .then((receipt) => {
    //         console.log(receipt);
    //         setRewarding({
    //             isRewarding:false,
                
    //             disabled: false
    //         });
    //         setOutput("You got a reward.");
    //         setTimeout(() => {
    //             setOutput('');
    //           }, 5000);
    //         // setFeedback(
    //         //   `Owner of the Pet.`);
    //         setRewarding({
    //             isRewarding:false,
                
    //             disabled: true
    //         });
    //       });
    //     }
        
    //     });
        
       

            
   
    // }



    const getTotalSupply = async (contract) =>{
        setSupply((prevState)=>({
            ...prevState,
            loading: true,
        }));
        contract.methods
        .totalSupply()
        .call()
        .then((supply) => {
            console.log("On getTotalSupply Supply: "+supply);
            setSupply(()=>({
                supply: supply,
                loading: false,
            }));
            return supply;
        })
        .catch((err) => {
            console.log(err);
        });
        
       

            
   
    }

    // const onMinted = async () => {
    //     await info.contract.on("Transfer", (from, to, amount, event)=>{
    //         console.log({from, to, amount, event});
    //     });
        
    // };
    const getPrice = async (contract) =>{
        
        contract.methods
        .getPrice()
        .call()
        .then((price) => {
            console.log(price);
            setMintingPrice(price);

        })
        .catch((err) => {
            console.log(err);
        });
    }

    const getPets = async (infoState) => {
        const localInitialPetSate = pets;
        setPets((prevState)=>({
            ...prevState,
            list:[],
            loading: true,
        }));
      
        // Returns a token ID owned by owner at a given index of its token list. Use along with balanceOf to enumerate all of owner's tokens.
        infoState.contract.methods
        .balanceOf(infoState.account)
        .call()
        .then((res) => {
            console.log(res);
            setPets((prevState)=>({
                ...prevState,
                count: res,
            }));
             var i;
            for(i= 0; i< res; i++){
                console.log("Entering");

                infoState.contract.methods
                .tokenOfOwnerByIndex(infoState.account, i)
                .call()
                .then((tokenId) => {
                    console.log(tokenId);
                    infoState.contract.methods
                    .getPetNumberHex(tokenId)
                    .call()
                    .then((number) => {
                        // listPetNumber.push(res);
                        console.log(number);
                        infoState.contract.methods
                        .tokenURI(tokenId)
                        .call()
                        .then((res) => {
                        //     // listPets[i] = res;

                            console.log(res);
                            fetch(res)
                                .then((response)=>{
                                console.log(response);
                                return response.json();
                                })
                                .then((data)=>{

                                    data.number = number;
                                    data.tokenId= tokenId;
                                    delete data.description;
                                    // listPets.push(data);
                                    console.log(data);
                                    setPets((prevState)=>({
                                        ...prevState,
                                        list: [...prevState.list, data],
                                    }));
                                })
                                .catch((err)=>{console.log(err)});
                    });
                     
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    });
        
            }
            setPets((prevState)=>({
                ...prevState,
                loading:false
                
            }));
        
        })
        .catch((err) => {
            console.log(err);
            setPets(localInitialPetSate);
        });
    
    //     getPetCount().then((res)=>{
    //     var i;
    //     for(i= 0; i< res; i++){
    //         console.log("Entereing");
    //         info.contract.methods
    //         .tokenOfOwnerByIndex(info.account, i)
    //         .call()
    //         .then((res) => {
    //             console.log(res);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         });
    //     }
    //  } );
        
    };

    console.log(info);
    useEffect(() =>{
        init();
        initOnChanged();
        // onMinted();
        // info.contract.on("Transfer", (from, to, amount, event)=>{
        //     console.log({from, to, amount, event});
        // });
        
    },[init]);
    // const dispatch = useDispatch();
    // useEffect(()=>{
    //     info.status && dispatch(getPets());
    // },[info]);

    // useEffect(()=>{
    //     let web3 = new Web3(window.ethereum);
    //     const mycontract = new web3.eth.Contract(contractTestnet.abi, contractTestnet.address);
    //     console.log("Attaching listener");  
    //     // mycontract.on("Transfer", (from, to, tokenId)=>{
    //     //     console.log({from, to, tokenId});
    //     // });     
    //     mycontract.events.Transfer({}, 
    //         function(error, event){ console.log(event);})
    //         .on('data',function(event){
    //         console.log(event);
    //         console.log("Before setSupply");
    //         // setSupply(()=>({
    //         //         supply: amount,
    //         //         loading: false,
    //         //     }));
    //         //     console.log('Amount');
    //         //     console.log({amount});

    //     }).on('error',function(error, receipt){ 
    //         console.log(error);
    //         console.log(receipt);
    //     }); 
    // },[]);

    const validateAddress = (rawInput)=>{
        
         try {
            const address = Web3.utils.toChecksumAddress(rawInput)
            
            return address;
          } catch(e) { 
            console.error('invalid ethereum address', e.message) 
            
          }
          return null;
       
    }
    const onTextChange = ()=>{
        console.log("HERE onTextChanged!");
        if (!textInput.current){return;}
        const rawInput = textInput.current.value;
        try {
           const address = Web3.utils.toChecksumAddress(rawInput)
           setChanging({
               disabled:false,
               isChanging:false
           });
           setOutput("Ready to Change Painter.");
         
         } catch(e) { 
           console.error('invalid ethereum address', e.message) 
           setChanging({
               disabled:true,
               isChanging:false
           });
           if (rawInput.length==0){
               setOutput('');
           }else{
            setOutput("Invalid Address: "+rawInput);   
           }
           
         }
       
      
   }
   
   const getPetByTokenId = async (contract)=>{
    if (!tokenIdInput.current){return;}
    const rawInput = tokenIdInput.current.value;
    const s = await getTotalSupply(contract);
    console.log('Supply: '+supplyInfo.supply+' or '+s);
    if (rawInput.length>0 && !isNaN(rawInput) && Number(supplyInfo.supply)>=Number(rawInput) && Number(rawInput)>0){
        setChangingToken({
            disabled:false,
            isChanging:false
        });
        setVisorPets((prevState)=>({
            ...prevState,
            loading:true,
            list: [],
        }));
        const tokenId = rawInput;
        setOutput('Getting the Pet Number '+tokenId+'...');
        contract.methods
                    .getPetNumberHex(tokenId)
                    .call()
                    .then((number) => {
                        // listPetNumber.push(res);
                        console.log(number);
                        contract.methods
                        .tokenURI(tokenId)
                        .call()
                        .then((res) => {
                        //     // listPets[i] = res;

                            console.log(res);
                            fetch(res)
                                .then((response)=>{
                                console.log(response);
                                return response.json();
                                })
                                .then((data)=>{

                                    data.number = number;
                                    data.tokenId= tokenId;
                                    delete data.description;
                                    // listPets.push(data);
                                    console.log(data);
                                    setVisorPets((prevState)=>({
                                        ...prevState,
                                        loading:false,
                                        list: [...prevState.list, data],
                                    }));
                                    setOutput('');
                                })
                                .catch((err)=>{
                                    console.log(err)
                                    setOutput("Sorry, something went wrong please try again later.");
                                    setTimeout(() => {
                                        setOutput('');
                                      }, 5000);
                                    setVisorPets((prevState)=>({
                                        ...prevState,
                                        loading:false,
                            
                                    }));
                                });
                            });
                     
                        })
                        .catch((err) => {
                            console.log(err);
                            setOutput("Sorry, something went wrong please try again later.");
                            setTimeout(() => {
                                setOutput('');
                              }, 5000);
                            setVisorPets((prevState)=>({
                                ...prevState,
                                loading:false,
                    
                            }));
                        });
                    


    }else{
        setChangingToken({
            disabled:true,
            isChanging:false
        });
        if (rawInput.length==0){
            setOutput('');
        }else{
         setOutput("Invalid Token Id: "+rawInput);   
        }
    }
    
}

   const onTokenIdChange = ()=>{
    console.log("HERE onTokenIdChanged!");
    if (!tokenIdInput.current){return;}
    const rawInput = tokenIdInput.current.value;
    // await getTotalSupply(info.contract);
    if (rawInput.length>0 && !isNaN(rawInput) && Number(rawInput)>0){
        setChangingToken({
            disabled:false,
            isChanging:false
        });
        setOutput('');
    }else{
        setChangingToken({
            disabled:true,
            isChanging:false
        });
        if (rawInput.length==0){
            setOutput('');
        }else{
         setOutput("Invalid Token Id: "+rawInput);   
        }
    }
    
}
    function newImage(item){
        let data = item.image;
        var d = window.open().document;
        d.write(''); d.close();
        d.body.appendChild(document.createElement('img')).src = data;
    }

    function ShowPetList(props){
        const pets = props.pets;
        const loading = pets.loading ? <p>Loading...</p> : null;
        const nopets = pets.count==0 ? <p>No Pets in this account, <a target="_blank" rel="noopener noreferrer" href={explorer+info.account}>{info.account}</a>.</p> : null;
        const liPet = pets.list.map((item) =>
            <li key={item.tokenId.toString()}>
            <figure >
                    <object data={item.image} type="image/svg+xml" >
                        <img src={item.image} style={{width:120, height:120}} alt={item.name}/>
                        </object>
                        
                    <figcaption>{item.name}</figcaption>

                </figure>
                </li>
        );
        return(
            <div>
            {loading}
            {nopets}
            <ul className="svgPet">
                {liPet}
            </ul>
            </div>
        );
    }

    function ShowPetListHex(props){
        const pets = props.pets;
        const loading = pets.loading ? <p>Loading...</p> : null;
        const nopets = pets.count==0 ? <p>No Pets in this account, <a target="_blank" rel="noopener noreferrer" href={'https://polygonscan.com/address/'+info.account}>{info.account}</a>.</p> : null;
        const liPet = pets.list.map((item) =>
            <li key={item.tokenId.toString()}>
                {item.name}: {item.number}
            </li>
        );
        return(
            <div>
            {loading}
            {nopets}
            <ul>
                {liPet}
            </ul>
            </div>
        );
    }

    function ToOpcode(props){
        let output = '';
        const ind_pet = props.ind_pet;
        console.log(ind_pet.number);
        let str =ind_pet.number.slice(2);
        for (let i = 0; i < str.length; i+=2) {
            let hex_rep = str[i]+str[i+1];
            console.log(hex_rep);
            output+=" "+opcodes[hex_rep];
        }
        console.log(output);
        return output;
    }

    function ShowPetListMMIX(props){
        const pets = props.pets;
        const loading = pets.loading ? <p>Loading...</p> : null;
        const nopets = pets.count==0 ? <p>No Pets in this account, <a target="_blank" rel="noopener noreferrer" href={explorer+info.account}>{info.account}</a>.</p> : null;
        
        const liPet = pets.list.map((item) =>
        
            <li key={item.tokenId.toString()}>
            <figure >
                    <svg xmlns="http://www.w3.org/2000/svg" width="500" height="500" font-size="32" text-anchor="middle">
                        <text y="125" fill="hsl(359,100%, 80%)">
                            
                            
                            <ToOpcode ind_pet={item}/>
                        </text>
                        </svg>
                       
                    <figcaption>{item.name}</figcaption>

                </figure>

                </li>
        );
        return(
            <div>
            {loading}
            {nopets}
            <ul className="svgPet">
                {liPet}
            </ul>
            </div>
        );
    }

    function ShowPetListConway(props){
        const pets = props.pets;
        const loading = pets.loading ? <p>Loading...</p> : null;
        const nopets = pets.count==0 ? <p>No Pets in this account, <a target="_blank" rel="noopener noreferrer" href={explorer+info.account}>{info.account}</a>.</p> : null;
        
        const liPet = pets.list.map((item) =>
            <li key={item.tokenId.toString()}>
            <figure >
                    <object data={item.image} type="image/svg+xml" >
                        <img src={item.image} style={{width:120, height:120}} alt={item.name}/>
                        </object>
                    
                    <figcaption>{item.name}</figcaption>

                </figure>

                </li>
        );
        return(
            <div>
            {loading}
            {nopets}
            <ul className="svgPet">
                {liPet}
            </ul>
            </div>
        );
    }


return (
    <div>
            <div className="notice" display={(info.connected)?'inline':'none'}>
                <p>{info.status}</p>
            </div>
        <div>
            <div>
            <p id="contractExp" className="contractExplorer">Contract: <a target="_blank" rel="noopener noreferrer" href={explorer+contractAddr}>{contractAddr}</a></p>
                
            </div>
            <div>
                <p id="supply" className="supplies">Minted: {supplyInfo.supply}</p>
                
                <p id="max_supply" className="supplies">First Generation Supply: 65536</p>
                {/* <p id="owner_supply" className="supplies">Owner Reserved Supply: 1024</p> */}
                {/* <p id="remained_supply" className="supplies">Remaining Free Supply: {511-supplyInfo.supply>0 ? 511-supplyInfo.supply : 0}</p> */}
                
            </div>
            <div>
                <p id="price">Minting Price: {mintingPrice/10**18} MATIC</p>
                <button disabled={!info.connected || minting} onClick={() => mintPet()}>Mint Your Own Pet Number</button>
                {/* <button disabled={!info.connected || rewarding.disabled} onClick={() => getReward()}>Egg Hunting Reward</button> */}
            
                <p id="output" >{output}</p>
            </div>
            
            {/* <button disabled={!info.connected} onClick={() => onMinted()}>activate listener</button> */}
        
            <div >
                <p>Advanced User Only</p> 
                <p>Ex.: Use 0xc7b7ED54e559709a18DACCF33B4f8eA7Ea0D62B2 for Sword Painter. </p>
                <p>Ex.: Use 0xB56F6F9fffE331B81AA686c7C4F51eb3B535b122 for Conway Painter.</p>
                <input disabled={!info.connected} type="text" name="painterAddress" ref={textInput} onChange={() => {onTextChange();}} placeholder="Painter Address"/>
                <button disabled={!info.connected || changing.disabled} onClick={() => changePainter()}>Change your Painter</button>
                
            </div>

            <div>
            </div>
        </div>
    {/* <div> */}

<Tabs>
    <TabList>
        <Tab>Your Pets</Tab>
        <Tab>Visor</Tab>
    </TabList>
{/* Own Pets */}
    <TabPanel>
        <Tabs>
            <TabList>
            <Tab>Images</Tab>
            <Tab>Hexadecimal</Tab>
            </TabList>

            <TabPanel>
            {/* {getPets()} */}
            {/* <button disabled={!info.connected} onClick={() => getPets()}>Get Pet Numbers</button> */}
            <ShowPetList pets={pets}/>
            
            </TabPanel>
            <TabPanel>
            
            <ShowPetListHex pets={pets}/>
            
            </TabPanel>
            
        </Tabs>
    </TabPanel>
{/* Visor */}
    <TabPanel>
    <div className="visor">
                <input onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()} type="text" name="tokenId" ref={tokenIdInput} onChange={() => {onTokenIdChange();}} placeholder="Token Id"/>
                {/* <input type="number" pattern="[0-9]*" disabled={!info.connected} name="tokenId" ref={tokenIdInput} onChange={() => {onTokenIdChange();}}/> */}
                <button disabled={changingToken.disabled} onClick={() => getPetByTokenId(contractVisor)}>View</button>
            </div>
        <Tabs>
            <TabList>
            <Tab>Images</Tab>
            <Tab>Hexadecimal</Tab>
            {/* <Tab>Game of Life</Tab> */}
            </TabList>

            <TabPanel>
            {/* {getPets()} */}
            {/* <button disabled={!info.connected} onClick={() => getPets()}>Get Pet Numbers</button> */}
            <ShowPetList pets={visorPets}/>
            
            </TabPanel>
            <TabPanel>
            
            <ShowPetListHex pets={visorPets}/>
            
            </TabPanel>
            {/* <TabPanel>
            
            <ShowPetListMMIX pets={visorPets}/>
            
            </TabPanel> */}
            
        </Tabs>
    </TabPanel>
    
</Tabs>
       
{/* <iframe src="data:application/json;base64,eyJuYW1lIjoiUGV0IE51bWJlciAxIiwiZGVzY3JpcHRpb24iOiJTaW5jZSBhbG1vc3QgYWxsIE5GVCBhcmUgZW5jb2RlZCBpbiBhIG51bWJlciwgd2Ugd2VudCBhaGVhZCBhbmQgY3JlYXRlZCBhIFBldCBOdW1iZXIgd2hpY2ggaXMgdGhlIG51bWJlciBpdHNlbGYuIEEgYmxhbmsgY2FudmFzIGZvciB5b3VyIGltYWdpbmF0aW9uLiBBbGwgZGF0YSBpcyBzdG9yZWQgb25jaGFpbi4gSXQgaGFzIDMyIGJ5dGVzIGFuZCBlYWNoIG9uZSByZXByZXNlbnQgYSBmZWF0dXJlIHdpdGggdXAgdG8gMjU2IHBvc3NpYmxlcyB0cmFpdHMuIFRoZSByYXJpdHkgaXMgY3JlYXRlZCBwZXIgZmVhdHVyZSAoYnl0ZSkgd2l0aCBwcm9iYWJpbGl0aWVzIG9mIDAuMDQlIGZvciAwLCAwLjA5JSBmb3IgMSwgMC4xMiUgZm9yIDIsIDAuMTUlIGZvciAzIGFuZCAwLjM5JSBmb3IgbnVtYmVycyA0IHRvIDI1NS4iLCJpbWFnZSI6ImRhdGE6aW1hZ2Uvc3ZnK3htbDtiYXNlNjQsUEhOMlp5QjRiV3h1Y3owaWFIUjBjRG92TDNkM2R5NTNNeTV2Y21jdk1qQXdNQzl6ZG1jaUlIZHBaSFJvUFNJMU1EQWlJR2hsYVdkb2REMGlOVEF3SWlCbWIyNTBMWE5wZW1VOUlqUXdJaUIwWlhoMExXRnVZMmh2Y2owaWJXbGtaR3hsSWo0OFpHVm1jejQ4YkdsdVpXRnlSM0poWkdsbGJuUWdhV1E5SW01SElpQm5jbUZrYVdWdWRGUnlZVzV6Wm05eWJUMGljbTkwWVhSbEtEUTFLU0krUEhOMGIzQWdiMlptYzJWMFBTSTFKU0lnYzNSdmNDMWpiMnh2Y2owaWFITnNLRGt6TERVd0pTd2dNalVsS1NJdlBqeHpkRzl3SUc5bVpuTmxkRDBpT1RVbElpQnpkRzl3TFdOdmJHOXlQU0pvYzJ3b01qY3dMRFV3SlN3Z01qVWxLU0l2UGp3dmJHbHVaV0Z5UjNKaFpHbGxiblErUEM5a1pXWnpQanh5WldOMElIZHBaSFJvUFNJMU1EQWlJR2hsYVdkb2REMGlOVEF3SWlCbWFXeHNQU0oxY213b0p5TnVSeWNwSWk4K1BIUmxlSFFnZUQwaU5UQWxJaUI1UFNJNE15SWdabWxzYkQwaWFITnNLRGczTERFd01DVXNJRGd3SlNraVBqWXpNamN4T1RrME1qZzJORGs4TDNSbGVIUStQSFJsZUhRZ2VEMGlOVEFsSWlCNVBTSXhOallpSUdacGJHdzlJbWh6YkNnNE55d3hNREFsTENBNE1DVXBJajQxTmpFNU56RTROREE1TnpJM056YzBQQzkwWlhoMFBqeDBaWGgwSUhnOUlqVXdKU0lnZVQwaU1qUTVJaUJtYVd4c1BTSm9jMndvT0Rjc01UQXdKU3dnT0RBbEtTSStORFV3T1RBM01UZzNNVEV5TWprM056d3ZkR1Y0ZEQ0OGRHVjRkQ0I0UFNJMU1DVWlJSGs5SWpNek1pSWdabWxzYkQwaWFITnNLRGczTERFd01DVXNJRGd3SlNraVBqSTNOek13T0RnMU5qVTRNall4TkRjOEwzUmxlSFErUEhSbGVIUWdlRDBpTlRBbElpQjVQU0kwTVRVaUlHWnBiR3c5SW1oemJDZzROeXd4TURBbExDQTRNQ1VwSWo0NE1qWXlNakl5TXpVeU1EazFNekU0UEM5MFpYaDBQand2YzNablBnPT0ifQ=="></iframe>
    <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MDAiIGhlaWdodD0iNTAwIiBmb250LXNpemU9IjQwIiB0ZXh0LWFuY2hvcj0ibWlkZGxlIj48ZGVmcz48bGluZWFyR3JhZGllbnQgaWQ9Im5HIiBncmFkaWVudFRyYW5zZm9ybT0icm90YXRlKDQ1KSI+PHN0b3Agb2Zmc2V0PSI1JSIgc3RvcC1jb2xvcj0iaHNsKDkzLDUwJSwgMjUlKSIvPjxzdG9wIG9mZnNldD0iOTUlIiBzdG9wLWNvbG9yPSJoc2woMjcwLDUwJSwgMjUlKSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHdpZHRoPSI1MDAiIGhlaWdodD0iNTAwIiBmaWxsPSJ1cmwoJyNuRycpIi8+PHRleHQgeD0iNTAlIiB5PSI4MyIgZmlsbD0iaHNsKDg3LDEwMCUsIDgwJSkiPjYzMjcxOTk0Mjg2NDk8L3RleHQ+PHRleHQgeD0iNTAlIiB5PSIxNjYiIGZpbGw9ImhzbCg4NywxMDAlLCA4MCUpIj41NjE5NzE4NDA5NzI3Nzc0PC90ZXh0Pjx0ZXh0IHg9IjUwJSIgeT0iMjQ5IiBmaWxsPSJoc2woODcsMTAwJSwgODAlKSI+NDUwOTA3MTg3MTEyMjk3NzwvdGV4dD48dGV4dCB4PSI1MCUiIHk9IjMzMiIgZmlsbD0iaHNsKDg3LDEwMCUsIDgwJSkiPjI3NzMwODg1NjU4MjYxNDc8L3RleHQ+PHRleHQgeD0iNTAlIiB5PSI0MTUiIGZpbGw9ImhzbCg4NywxMDAlLCA4MCUpIj44MjYyMjIyMzUyMDk1MzE4PC90ZXh0Pjwvc3ZnPg=="></img> */}
    </div>
    // </div>
    );
};

export default Pet;