import React from 'react';
import ReactDOM from 'react-dom';
import Pet from './components/Pet';
import './Pet.css';

ReactDOM.render(
  <React.StrictMode>
    <h1>Pet Number</h1>
    <div>

      <Pet />
    </div>
  </React.StrictMode>
  ,
  document.getElementById('root')
);

